.preloader {
  background: #FFFFFF;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 8888;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  img {
    width: 91px;
    height: 91px;
  }

  p {
    margin-top: 8px;
    font-size: 14px;
    line-height: 19px;
    text-align: center;
  }
}
